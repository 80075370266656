<template>
  <section>
    <a-form class="form-container" :form="form" @submit="submitForm">
      <a-row type="flex" :gutter="20">
        <a-col :span="8">
          <a-form-item label="领取人">
            <a-input
              autocomplete="off"
              v-decorator="[
                'receiver',
                {
                  rules: [{ required: true, message: '请填写领取人' }, { max: 30, message: '最大输入30个汉字' },],
                },
              ]"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <!-- :default-value="moment(new Date(), 'YYYY/MM/DD')" -->
          <a-form-item label="领取时间 ">
            <a-date-picker
              v-decorator="[
                'outTime',
                {
                  rules: [{ required: true, message: '请填写领取时间' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="经办人 ">
            <a-input disabled :defaultValue="userNickname"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row type="flex" :gutter="20">
        <a-col :span="8">
          <a-form-item label="出库单据编号">
            <a-input
              v-decorator="[
                'code',
                {
                  rules: [{ required: true, message: '请填写出库单据编号' }],
                },
              ]"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="所属仓库">
            <a-select
              placeholder="请选择"
              @change="handleChange"
              v-decorator="[
                'warehouseId',
                {
                  rules: [{ required: true, message: '请选择仓库' }],
                },
              ]"
            >
              <a-select-option
                v-for="item in warehouseData"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <section class="list-table-container list-table">
        <a-table
          :bordered="false"
          :data-source="dataSource"
          :pagination="false"
          size="small"
          class="m-t-18"
          :columns="columns"
        >
          <template slot="name" slot-scope="list, text">
            <span>{{ text.name }}</span>
            <!-- <a-cascader
              :options="options"
              @change="value => onChangeMaterial(value, list)"
              placeholder="请选择"
              :display-render="displayRender"
            /> -->
          </template>
          <template slot="brand" slot-scope="list, text">
            <span>{{ text.brand }}</span>
          </template>
          <template slot="type" slot-scope="list, text">
            <span>{{ text.type }}</span>
          </template>
          <template slot="unit" slot-scope="list, text">
            <span>{{ text.unit }}</span>
          </template>
          <template slot="num" slot-scope="list, text">
            <span>{{ text.num }}</span>
          </template>
          <template slot="receive" slot-scope="list, text">
            <a-input
              :min="0"
              :max="text.num"
              v-model="text.receive"
              type="number"
            />
          </template>
          <template slot="operation" slot-scope="list, text">
            <a @click="delDataSource(text.id)">删除</a>
          </template>
          <template slot="positon" slot-scope="list, text">
            <a-input v-model="text.position" />
          </template>
        </a-table>
        <a-row class="search-container addData" type="flex" justify="center">
          <a-button @click="addData">新增</a-button>
        </a-row>
        <a-row class="totalNum" type="flex" justify="start">
          <span style="width: 90px">合计</span>

          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>

          <span>{{ TotalNum }}</span>
        </a-row>
      </section>

      <a-row type="flex" :gutter="20">
        <a-col :span="24">
          <a-form-item label="备注">
            <a-textarea rows="4" v-decorator="['remarks']"></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-btn-group">
        <a-col :span="8">
          <a-button html-type="submit"><a-icon type="save" />保存</a-button>
          <a-button @click="$router.back()">取消</a-button>
        </a-col>
      </a-row>
    </a-form>

    <!-- 模态框 -->
    <a-modal
      centered
      width="880px"
      class="modal-container"
      :title="typeModal.title"
      v-model="typeModal.visible"
      :destroyOnClose="true"
      :footer="false"
    >
      <a-form :form="materialform" @submit="addMaterial">
        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="材料"
        >
          <a-cascader
            :options="options"
            @change="(value) => onChangeMaterial(value, options)"
            placeholder="请选择"
            :display-render="displayRender"
            v-decorator="[
              'companyNames',
              { rules: [{ required: true, message: '类型名称不能为空' }] },
            ]"
          />
        </a-form-item>

        <a-form-item
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="分类名称"
        >
          <section class="list-table-container list-table">
            <a-table
              :rowSelection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
              :bordered="false"
              :data-source="materialSource"
              size="small"
              class="m-t-36"
              :columns="materialColumns"
              :pagination="false"
              :scroll="{ y: 500 }"
            >
            </a-table>
          </section>
        </a-form-item>
        <a-row class="form-btn-group">
          <a-button class="btn-save" html-type="submit"
            ><a-icon type="save" />保存</a-button
          >
          <a-button class="btn-cancel" @click="typeModal.visible = false"
            >取消</a-button
          >
        </a-row>
      </a-form>
    </a-modal>
  </section>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      selectedRowKeys: [],
      materialColumns: [
        { title: "序号", dataIndex: "order", align: "center" },
        { title: "品牌", dataIndex: "brand", align: "center" },
        { title: "规格型号", dataIndex: "type", align: "center" },
        { title: "库存数量", dataIndex: "num", align: "center" },
        {
          title: "计量单位",
          dataIndex: "unit",
          align: "center",
        },
      ],
      materialSource: [],
      typeModal: {
        visible: false,
        title: "添加",
      },
      columns: [
        { title: "序号", dataIndex: "order", align: "center", width: 100 },
        {
          title: "材料名称",
          dataIndex: "name",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "name" },
        },
        {
          title: "品牌",
          dataIndex: "brand",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "brand" },
        },
        {
          title: "规格型号",
          dataIndex: "type",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "type" },
        },
        {
          title: "计量单位",
          dataIndex: "unit",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "unit" },
        },
        {
          title: "库存数量",
          dataIndex: "num",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "num" },
        },
        {
          title: "领取数量",
          dataIndex: "receive",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "receive" },
        },
        {
          title: "使用部位",
          dataIndex: "positon",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "positon" },
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 200,
          scopedSlots: { customRender: "operation" },
        },
      ],
      dataSource: [],
      totaldataSource: [],
      code: "", //出库编码
      warehouseData: [], //仓库
      warehouseId: "", //仓库id
      categoryId: "", //材料id
      skuID: "",
      options: [],
      addMaterialName: "", //新增材料名称
      materialPageNum: 0,
      materialTotal: 0,
    };
  },
  created() {
    this.getOutStorageNextCode(); //出库编码
    this.warehouse(); //仓库下拉
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
    this.materialform = this.$form.createForm(this);
  },
  mounted() {},
  computed: {
    TotalNum() {
      let d = 0;
      this.dataSource.forEach((item) => {
        d = (parseInt(item.receive) ? parseInt(item.receive) : 0) + d;
      });
      return d;
    },
     userNickname() {
      return this.$store.state.userInfo.userNickname;
    },
  },
  methods: {
    // 材料分页
    changePageNum(p) {
      this.materialPageNum = p.current;

      this.getSkuList();
    },
    // 删除材料
    delDataSource(id) {
      // console.log(this.selectedRowKeys,id)
      this.dataSource = this.dataSource.filter((item) => {
        if (item.id != id) return item;
      });
      this.dataSource.forEach((item, index) => {
        item.order = index + 1;
      });
      this.selectedRowKeys = this.selectedRowKeys.filter((item) => {
        if (item != id) return item;
      });
    },
    // 材料下拉
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },

    moment,
    // 添加保存材料
    addMaterial(e) {
      e && e.preventDefault();
      this.materialform.validateFields((err, values) => {
        if (!err) {
          // 当前材料id
          let nowmaterialId =
            values.companyNames[values.companyNames.length - 1];
          this.options.forEach((item) => {
            if (item.id == nowmaterialId) {
              this.addMaterialName = item.name;
              return;
            }
            if (item.children && item.children.length > 0) {
              item.children.forEach((items) => {
                if (items.id == this.categoryId) {
                  this.addMaterialName = items.name;
                  return;
                }
              });
            }
          });
          this.selectedRowKeys.forEach((item) => {
            this.materialSource.forEach((v) => {
              if (item == v.key) {
                this.dataSource.push(v);
              }
            });
          });
          let uniq = this.uniq(this.dataSource, "id");
          this.dataSource = uniq;
          this.dataSource.forEach((item, index) => {
            item.order = index + 1;
          });
          // this.selectedRowKeys.forEach((item) => {
          //   this.materialSource.forEach((v) => {
          //     if (item == v.key) {
          //       this.totaldataSource.push(v);
          //     }
          //   });
          // });

          // let uniq = this.uniq(this.totaldataSource, "type");

          // this.dataSource = uniq;
          this.typeModal.visible = false;
        }
      });
    },
    // 保存新加
    submitForm(e) {
      e && e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let params = {};
          params.code = values.code;
          params.outTime = values.outTime
            ? values.outTime.format("YYYY-MM-DD hh:mm:ss")
            : "";
          params.receiver = values.receiver;
          params.remarks = values.remarks;
          params.warehouseId = values.warehouseId;
          params.detailList = [];

          this.dataSource.forEach((item) => {
            params.detailList.push({
              usage: item.position,
              skuId: item.skuID,
              outQty: parseInt(item.receive),
            });
          });

          this.$api.addOutStorage(params).then((res) => {
            if (res.code == "200") {
              // this.$message.success("新增成功!");

              this.$router.push({ path: "/mms/inout/warehouseOut/list" });
            }
          });
        }
      });
    },
    // 出库编码
    getOutStorageNextCode() {
      this.$api.getOutStorageNextCode().then((res) => {
        if (res.code == "200") {
          this.code = res.data;
          // 初始化 编码和时间
          this.form.setFieldsValue({
            outTime: moment(new Date(), "YYYY/MM/DD"),
            code: this.code,
          });
        }
      });
    },
    // 选择材料
    onChangeMaterial(value) {
      this.categoryId = value[value.length - 1];
      this.getSkuList();
      // this.$api
      //   .getSkuList({ categoryId: this.categoryId })
      //   .then((res) => {
      //     if (res.code == "200") {
      //       let records = res.data.records;
      //       if (records && records.length > 0) {
      //         records.forEach((item, index) => {
      //           item.key = index + 1;
      //           item.skuID = item.id;
      //           item.num = 0;
      //         });
      //       }
      //       this.materialSource = records;
      //     }
      //   })
      //   .then(() => {
      //     this.materialSource.forEach((item) => {
      //       this.getQtyData(item.skuID);
      //     });
      //   });
    },

    getSkuList() {
      this.$api
        .getSkuList({
          categoryId: this.categoryId,
          current: this.materialPageNum,
          size:1000,
        })
        .then((res) => {
          if (res.code == "200") {
            let records = res.data.records;
            this.materialTotal = res.data.total;

            if (records && records.length > 0) {
              records.forEach((item, index) => {
                item.order = index + 1;
                item.key = item.id;
                item.skuID = item.id;
                item.num = 0;
                item.orderQty = 0;
                item.oriUnitPrice = 0;
                // item.discountStatus = "false";
                item.actualAmountStatus = "false";
                item.orderQtyStatus = "false";
                item.oriUnitPriceStatus = "false";
                item.discount = 0;
                item.factPrice = 0;
                item.actualAmount = 0;
                item.actualPrice = 0;
              });

              this.materialSource = records;
            }
            // if (records && records.length > 0) {
            //   records.forEach((item, index) => {
            //     item.key = item.id;
            //     item.skuID = item.id;
            //     item.num = 0;
            //     item.order = index + 1;
            //   });
            // }
            // this.materialSource = records;
          }
        })
        .then(() => {
          this.materialSource.forEach((item) => {
            this.getQtyData(item.skuID);
          });
        });
    },
    // 查库存
    getQtyData(skuID) {
      let params = {
        skuId: skuID,
        warehouseId: this.warehouseId,
      };
      this.$api.getQty(params).then((res) => {
        if (res.code == "200") {
          this.materialSource.forEach((item) => {
            if (item.skuID == skuID) {
              item.num = res.data;
            }
          });
          this.dataSource.forEach((item) => {
            if (item.skuID == skuID) {
              item.num = res.data;
            }
          });
        }
      });
    },
    // 仓库下拉列表
    warehouse() {
      this.$api.warehouseData().then((res) => {
        if (res.code == "200") {
          this.warehouseData = res.data ? res.data : [];
          this.warehouseId = this.warehouseData[0].id;
          this.form.setFieldsValue({
            warehouseId: this.warehouseData[0].id,
          });
        }
      });
    },
    // 新增
    addData() {
      this.typeModal.visible = true;
      this.materialSource = [];

      //this.selectedRowKeys = [];

      // // 查询材料
      this.$api.getCategoryList().then((res) => {
        if (res.code == "200") {
          let options = res.data;
          let arr = this.formTreeData(options);
          this.options = arr;
        }
      });
    },
    // 树状图
    formTreeData(arr) {
      arr.forEach((item) => {
        item.value = item.id;
        item.label = item.name;
        // item.scopedSlots = { title: "value" };
        if (item.children && item.children.length > 0) {
          this.formTreeData(item.children);
        }
      });
      return arr;
    },
    uniq(arr, key) {
      let newobj = {};
      let newArr = [];
      for (let i = 0; i < arr.length; i++) {
        var item = arr[i];
        if (!newobj[item[key]]) {
          newobj[item[key]] = newArr.push(item);
        }
      }
      return newArr;
    },
    // 数组去重
    array_diff(a, b) {
      for (var i = 0; i < b.length; i++) {
        for (var j = 0; j < a.length; j++) {
          if (a[j].id == b[i]) {
            a.splice(j, 1);
            j = j - 1;
          }
        }
      }
      return a;
    },
    // 获取仓库id
    handleChange(val) {
      this.warehouseId = val;
      this.dataSource.forEach((item) => {
        console.log(item.skuID);
        this.getQtyData(item.skuID);
      });
    },
    // 选择添加材料
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.ant-calendar-picker-input {
  width: 100% !important;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
/deep/.ant-input[disabled] {
  color: rgba(255, 255, 255, 0.8);
  background: #3f72ba;
  cursor: not-allowed;
  opacity: 1;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
/deep/.ant-cascader-picker-label {
  color: #93bbf3 !important;
}
/deep/.ant-cascader-picker {
  background-color: #1c3d6c;
  border: none;
  outline: none;
}
/deep/.ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #1c3d6c;
}
.addData {
  padding: 8px 0;
  border: 1px solid #1c3d6c;
  border-top: 0;
  //   border-bottom: 0;
}
.totalNum {
  border: 1px solid #1c3d6c;
  border-top: 0;
  height: 46px;
  span {
    width: 178px;
    text-align: center;
    color: #93bbf3;
    height: 46px;
    line-height: 46px;
  }
}

.textarea,
.form-container {
  margin-top: 40px;
}
/deep/.list-table .ant-table {
  color: #93bbf3;
}
/deep/.ant-table-scroll
  > .ant-table-header
  > table
  > .ant-table-thead
  > tr
  > th {
  color: #fff;
}
/deep/.ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td {
  background: #1c3d6c;
}
</style>